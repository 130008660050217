<template>
  <b-card class="permission-list px-2">
    <!-- search input -->
    <div class="d-flex mb-1">
      <b-input-group>
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input v-model.lazy="searchword" placeholder="Tìm kiếm" @input="search" />
      </b-input-group>
      <div class="ml-1">
        <b-overlay :show="loading" block opacity="0.6" spinner-variant="primary">
          <b-button
            v-if="
              $can(
                permissionCode.ROLE_PERMISSION.action ||
                  permissionCode.permissionCode.STAFF_UPDATE_PERMISSSION.action,
                permissionCode.STAFF_UPDATE_PERMISSSION.resource,
              )
            "
            variant="primary"
            class="text-nowrap"
            @click="handleClick"
          >
            <span>{{ $t('Update') }}</span>
          </b-button>
        </b-overlay>
      </div>
    </div>
    <permission ref="permission" :per-check="perCheck" />
  </b-card>
</template>

<script>
import {
  BOverlay,
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
  BCard,
  BButton,
} from 'bootstrap-vue';
import Permission from '@core/components/permission/Permission.vue';
import permissionCode from '@/constants/permissionCode';

export default {
  components: {
    Permission,
    BOverlay,
    BCard,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BButton,
  },
  props: {
    perCheck: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchword: '',
      permissionCode,
    };
  },
  methods: {
    handleClick() {
      // let data = [];
      let arrayPermissionCheck = this.$refs.permission.$refs.tree.getCheckedNodes();
      arrayPermissionCheck = arrayPermissionCheck.reduce((filter, per) => {
        if (per.id) {
          const item = { permissionId: per.id.split('_')[1], limited: per.id.split('_')[0] };
          filter.push(item);
        }
        return filter;
      }, []);
      this.$emit('update-permission', { permissions: arrayPermissionCheck });
    },
    search() {
      this.$refs.tree.searchNodes(this.searchword);
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/tree.scss';
.permission-list {
  .card-body {
    padding-left: 0;
    padding-right: 0;
  }
  .list-permission {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .node-title {
      padding: 0;
      margin: 0;
    }
    .halo-tree {
      .node-title {
        padding: 0;
        margin: 0;
      }
      .check {
        left: -2px;
      }
      li::before {
        display: block;
      }
      li::after {
        display: block;
        width: 7px;
      }
      li {
        width: 100%;
      }
      // .leaf {
      //   display: inline-block;
      //   width: 33%;
      // }
    }
    li::before,
    li::after {
      display: none;
    }
    li {
      width: 30%;
      padding-left: 0;
      margin-left: 15px;
    }
    .inputCheck {
      &.box-checked:after {
        background-color: #28c76f;
        color: #fff;
      }
    }
  }
}
</style>
